import React from 'react';
import loadable from '@loadable/component';
import config from '../../config';
import Preloader from '../../components/Preloader';

const OrganizationsEnrichPage = loadable(() => import(
  /* webpackChunkName: "organizations-enrich-page-chunk" */
  './OrganizationsEnrichPage'
), {
  fallback: (<Preloader isDefault={config.isLogisticForce || config.isTerborg} />),
});

export default OrganizationsEnrichPage;
