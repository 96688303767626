import React from 'react';
import loadable from '@loadable/component';
import config from '../../config';
import Preloader from '../../components/Preloader';

const ReportsPage = loadable(
  () => import(
    /* webpackChunkName: "reports-page-chunk" */
    './ReportsPage'
  ),
  {
    fallback: (
      <div className="pageMainWrapper">
        <Preloader isDefault={config.isLogisticForce || config.isTerborg} />
      </div>
    ),
  }
);

export default ReportsPage;
